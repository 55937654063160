<template>
    <div class="video_view">
        <!-- 视频区 -->
        <div class="video-top">
            <!-- 暂停遮罩图 -->
            <div class="bg-img" v-show="liveStop" @click="reloadPlayer">
                <img class="bgc" :src="videoInfo.avatar" draggable="false" />
            </div>
            <!-- 阿里云播放 -->
            <div v-show="isLive" class="prism-player" id="player-con"></div>
            <!-- 倒计时 -->
            <div class="count_time" v-if="isLive == false">
                <div>
                    <p class="tips">{{ timeString }}</p>
                    <!-- 预约状态 -->
                    <div class="live-booking" v-if="bookingType">
                        <!-- <span class="lb-btn" :style="{ opacity: isBooking ? 0.5 : 1 }" @click="handleBooking">
                            {{ isBooking ? "预约成功" : "立即预约" }}
                        </span> -->

                        <template v-if="gid != '40112c45-7028-4191-a02d-9d0c9901bf49'">
                            <span v-if="isBooking == false" class="lb-btn Cursor" @click="handleBooking">立即预约</span>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <div class="video-bottom">
            <div class="vb-l">{{ videoInfo.title }}</div>
            <div class="vb-r">
                <img src="../../assets/img/admin.png" draggable="false" />
                <span>{{ videoInfo.watchNumber }}</span>
            </div>
        </div>
    </div>
</template>

<script>
let devPlayer = null;

export default {
    name: "Video",
    data() {
        return {
            bookingType: false, // 是否需要预约
            isBooking: false, // 是否预约
            liveStop: false, // 直播暂停
            onM3u8Retry: false, // 断流加载
            videoInfo: {
                appointStatus: 0,
                avatar: "",
                title: "",
                watchNumber: "",
                liveTime: 0,
                liveUrl: "",
                sequence: "",
                liveAuthStatus: ""
            },
            isLive: false,
            timeString: "",
            timer: null,
            player: null,
            skinLayout: [
                // { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                { name: "H5Loading", align: "cc" },
                { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
                { name: "infoDisplay" },
                { name: "tooltip", align: "blabs", x: 0, y: 56 },
                { name: "thumbnail" },
                {
                    name: "controlBar",
                    align: "blabs",
                    x: 0,
                    y: 0,
                    children: [
                        { name: "playButton", align: "tl", x: 15, y: 12 },
                        { name: "fullScreenButton", align: "tr", x: 10, y: 12 },
                        { name: "volume", align: "tr", x: 5, y: 10 }
                    ]
                }
            ],
            userId: localStorage.getItem("userId") || "",
            token: localStorage.getItem("token") || "",
            gid: sessionStorage.getItem("l-gid") || ""
        };
    },
    created() {
        this.gId = this.$route.params.gId || "";
    },
    methods: {
        // 视频预约
        handleBooking() {
            // 判断是否登录  去登录
            // 判断是否需要购买
            if (this.sequence == "3" && this.liveAuthStatus == "0") {
                this.$message("此直播为付费后可观看，请先付费报名观看！");
                return;
            }

            if (this.isBooking) return;

            this.$loading({
                duration: 0,
                forbidClick: true,
                message: "预约中..."
            });
            // 判断是否登录
            if (!(this.userId && this.token)) {
                this.$message.warning("您未登陆，请登陆账号进行直播预约！");
                setTimeout(() => {
                    this.$loading().close();
                    this.$router.push({ name: "Login" });
                    sessionStorage.setItem("RedirectPage", true);
                }, 2500);
                return;
            }

            this.$axios
                .post("/web/live/addAppoint", this.$qs.stringify({ courseGid: this.gid }))
                .then(res => {
                    if (res.data.code == 1) {
                        this.isBooking = true;
                        this.$message.success("预约成功");
                    }
                    this.$loading().close();
                })
                .catch(err => {
                    this.$loading().close();
                    this.$message.error("请求失败");
                });
        },
        // 初始化视频数据
        initVideo(data) {
            this.videoInfo = data;
            let { gid, appointStatus, avatar, title, watchNumber, liveTime, liveUrl, liveType, sequence, liveAuthStatus } = data;
            // 用户信息
            this.gid = gid;

            if (liveType == "4") {
                this.isLive = false;
                this.timeString = "直播已结束";
                this.liveStop = true;
            } else {
                if (liveTime == 0) {
                    this.isLive = true;
                    this.initAliplayer(avatar, liveUrl);
                } else {
                    this.bookingType = true;
                    this.isBooking = !!appointStatus;
                    if (liveTime <= 600) {
                        if (!appointStatus) {
                            this.bookingType = false;
                        }
                    }
                    this.isLive = false;
                    this.liveStop = true;
                    this.countDown(liveTime);
                    this.sequence = sequence;
                    this.liveAuthStatus = liveAuthStatus;
                }
            }
        },
        // 播放视频
        playVideo() {
            devPlayer.play();
        },
        // 暂停直播
        stopLive() {
            devPlayer && devPlayer.pause();
            // devPlayer.setCover(this.videoInfo.avatar);
            this.liveStop = true;
            this.isLive = false;
            this.timeString = "直播已结束";
            this.$emit("update:play-show", false); // 显示播放按钮
        },
        // 初始化播放器
        initAliplayer(avatar, course) {
            let player = devPlayer;
            if (player) {
                player.pause();
                player.dispose();
            }
            devPlayer = null;
            var that = this;
            this.onM3u8Retry = false;
            this.$emit("update:play-show", true); // 显示播放按钮
            devPlayer = new Aliplayer(
                {
                    id: "player-con",
                    source: course, // 直播地址
                    width: "100%",
                    height: "562.5px", //"4.2rem",
                    cover: avatar, // 视频封面
                    autoplay: false,
                    isLive: true,
                    rePlay: false,
                    playsinline: true,
                    preload: true,
                    controlBarVisibility: "click",
                    useH5Prism: true,
                    skinLayout: this.skinLayout
                },
                function (player) {
                    player.on("canplay", () => {
                        if (that.onM3u8Retry) {
                            that.liveStop = false;
                            that.onM3u8Retry = false;
                        }
                    });
                    player.on("pause", () => {
                        that.$emit("update:play-show", true);
                    });
                    player.on("play", () => {
                        that.$emit("update:play-show", false);
                        that.liveStop = false;
                    });

                    player.on("liveStreamStop", () => {
                        if (that.onM3u8Retry) {
                            that.liveStop = true;
                            that.onM3u8Retry = false;
                        }
                        that.$emit("update:play-show", false);
                    });
                    player.on("onM3u8Retry", () => {
                        that.liveStop = true;
                        that.onM3u8Retry = true;
                    });

                    player.on('seeking', () => {
                        });
                }
            );
        },
        // 重播
        reloadPlayer() {
            if (!this.isLive) return;
            let avatar = this.videoInfo.avatar,
                course = this.videoInfo.liveUrl;
            this.initAliplayer(avatar, course);
        },
        // 激活播放器
        activePlayer() {
            // let player = devPlayer;
            // player.loadByUrl(this.videoInfo.liveUrl);
            // this.$emit("update:paused", true); // 显示播放按钮
            // setTimeout(() => {
            // 	player.play();
            // }, 5);
            this.isLive = true;
            this.initAliplayer(this.videoInfo.avatar, this.videoInfo.liveUrl);
        },
        // 倒计时
        countDown(time) {
            if (time == 0) {
                this.timer && clearTimeout(this.timer);
                // this.isLive = true;
                // this.checkPlay = true;
                this.activePlayer();
                this.timeString = "";
                return;
            }
            const addZero = n => (n < 10 ? "0" + n : n);
            const checkTime = (n, type) => (n ? n + type : "");
            let diff = time - 1;
            let d = Math.floor(diff / 3600 / 24),
                h = Math.floor((diff / 3600) % 24),
                m = Math.floor((diff / 60) % 60),
                s = Math.floor(diff % 60);
            d = addZero(d);
            h = addZero(h);
            m = addZero(m);
            s = addZero(s);
            d = d === "00" ? 0 : d;
            if (h === "00") h = d === 0 ? 0 : h;
            if (m === "00") m = d === 0 && h === 0 ? 0 : m;
            let _d = checkTime(d, "天"),
                _h = checkTime(h, "时"),
                _m = checkTime(m, "分");
            this.timeString = `距开始${_d}${_h}${_m}${s + "秒"}`;
            // 递归调用
            this.timer = setTimeout(() => {
                this.countDown(diff);
            }, 1000);
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
        this.timer = null;
        if (devPlayer != null) {
            devPlayer.dispose();
            devPlayer = null;
        }
    },
    destroyed() {
        if (devPlayer != null) {
            devPlayer.dispose();
            devPlayer = null;
        }
    }
};
</script>

<style scoped>
.video_view {
    /* padding: 0 100px;
   */
    margin: 0 auto;
    width: 1000px;
    height: auto;
    background: #fff;
    font-size: 16px;
}

.video-top {
    height: 562.5px;
    background: linear-gradient(360deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.56) 0%, rgba(0, 0, 0, 0.42) 68%, rgba(0, 0, 0, 0) 100%);
    overflow: hidden;
    position: relative;
}

.bg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    /* z-index: 10011; */
}

.bg-img img {
    width: 100%;
    height: 100%;
}

/* 播放器 */
.count_time {
    width: 100%;
    height: 100%;
    font-size: 18px;
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    letter-spacing: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tips {
    font-size: 16px;
    line-height: 50px;
    color: #ddd;
}

/* // 预约按钮 */
.live-booking {
    line-height: 30px;
    height: 30px;
}

.lb-btn {
    display: inline-block;
    width: 100px;
    line-height: 30px;
    background: #ffa03a;
    border-radius: 16px;
    font-size: 15px;
}

.video-bottom {
    border-bottom: 8px solid #f6f6f6;
    padding: 0 10px;
    height: 45px;
    font-size: 17px;
    background: #fefefe;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    align-items: center;
}

.vb-l {
    flex: 1;
    letter-spacing: 1px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
}

.vb-r {
    width: auto;
    height: 20px;
    line-height: 20px;
    font-weight: 500;
}

.vb-r img {
    margin-right: 5px;
    width: 14px;
    height: 14px;
    vertical-align: middle;
}

.vb-r span {
    font-size: 12px;
    color: #999;
}

div#player-con {
    width: 100%;
    /* height: 450px !important; */
}
</style>
