<template>
    <div id="LiveList">
        <ul class="liveClass" v-if="liveList.length != 0">
            <template v-for="(item, index) in liveList">
                <li :key="index" @click="gotoVideoPlayer(item)" class="liveStep">
                    <div class="time">
                        <div class="year">{{ checkTime(item.liveTime, 0) }}年</div>
                        <div class="month">{{ checkTime(item.liveTime, 1) }}月</div>
                        <div class="day">{{ checkTime(item.liveTime, 2) }}日</div>
                    </div>
                    <div class="liveInfo">
                        <div class="classTitle">{{ item.title }}</div>
                        <div class="classAuthor">{{ item.liveTime }}</div>
                        <div class="address">{{ item.type }}</div>
                    </div>
                    <img :src="pic + item.avatar" alt="" class="liveImg" />
                </li>
            </template>
        </ul>

        <el-empty v-if="noData" description="暂无数据" />
    </div>
</template>

<script>
export default {
    name: "LiveList",
    components: {},
    data() {
        return {
            userId: localStorage.getItem("userId") || "",
            pic: "https://www.orthoday.com/SDpic/common/picSelect?gid=",
            liveList: [],
            noData:false
        };
    },
    created() {},
    computed: {
        checkTime() {
            return (date, type) => {
                let d = date.split(" ")[0],
                    dArr = d.split("-");
                return dArr[type];
            };
        }
    },
    mounted() {
        this.getData();
        this.addDataBuried();
    },
    methods: {
        addDataBuried() {
            let headers = {
                equipmentType: "3", //设备类型： 1：安卓、  2 ：IOS 、 3 ： H5
                phoneModel: "", //手机型号
                appVersionNumber: "" //app版本号
            };
            this.$axios
                .post(
                    "/SD/statistic/addDataBuried",
                    this.$qs.stringify({
                        usergid: this.userId,
                        moduleType: "11",
                        subModuleType: "2",
                        locationType: "1",
                        disMobile: "PC"
                    }),
                    {
                        headers
                    }
                )
                .then(res => {});
        },
        getData() {
            this.$axios.post("/web/live/queryLiveList").then(res => {
                if (res.data.code == 1) {
                    this.liveList = res.data.result_data;
                    this.noData = res.data.result_data.length == 0
                }
            });
        },

        gotoVideoPlayer(item) {
            sessionStorage.setItem("l-gid", item.gid);
            let pushParams = { name: "Live", params: { gId: item.gid } };
            this.$router.push(pushParams);
        }
    }
};
</script>

<style lang="less" scoped>
#LiveList {
    background-color: #fff;
    .el-empty {
        min-height: 700px;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 40px;
    }
}

.liveClass {
    background-color: #fff;
    margin: 0 70px;
    min-height: 500px;
}

.liveStep {
    background-color: #fff;
    height: 138px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e8eaec;
}
.liveStep:hover {
    cursor: pointer;
}

/* 时间部分 */
.time {
    width: 63px;
    margin: 16px 0;
    text-align: center;
}

.time div {
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    font-size: 12px;
    color: #fff;
    font-weight: 300;
}

.year {
    background-color: #5680FA;
}

.month {
    background-color: #19be6b;
    margin: 5px 0;
}

.day {
    background-color: #f90;
    margin: 5px 0;
}

/* 信息部分 */
.liveInfo {
    margin: 16px;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 260px);
}

.liveInfo div {
    height: 32px;
    line-height: 32px;
}

.classTitle {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}

.classAuthor {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
}

.address {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
}

/* 图片部分 */
.liveImg {
    width: 180px;
    height: 100px;
    margin: auto 0;
}
</style>
