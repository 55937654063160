<template>
    <div id="Search">
        <div class="inputDiv">
            <el-input v-model="input" placeholder="请输入内容" class="input"></el-input>
            <el-button type="primary" round size="small" @click="searchClick()" style="background-color: #5680FA;border-color: #5680FA" :style="{opacity:(!input?0.5:1)}" :disabled="!input" >查询</el-button>
            <el-button class="reset" round size="small" @click="showView = 1,input = '',pageNum = 1">清除</el-button>
        </div>

        <div v-if="showView == 1">
            <div class="hotSearch">
                <span class="hotTitle">热门搜索</span>
                <img src="../../assets/img/hotSearch.png" alt="" />

                <div class="hotList">
                    <div v-for="(item, index) in hotTagList" :key="index" class="hotItem" @click="searchVideo(item.keyword)">
                        <p>
                            <span :class="index < 3 ? 'redColor' : 'blackColor'">{{ index + 1 }}</span
                            ><span>{{ item.keyword }}</span
                            ><span class="subKeyword">{{ item.subKeyword }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showView == 2">
            <DataList :dataArray="videoList" @transfer="toDetail" />
            <div class="pageBar">
                <span @click="prev" :style="{background:(pageNum==1?'#9e9e9e':'#5680FA')}">上一页</span>
                <span @click="next" :style="{background:(videoList.length < 20?'#9e9e9e':'#5680FA')}">下一页</span>

                <!-- <el-pagination
          layout="prev, pager, next"
          :current-page.sync="pageNum"
          :page-size="20"
		  :total="totcalCount"
          @current-change="changePageNum"
        ></el-pagination> -->
            </div>
        </div>

        <div v-if="showView == 3" class="blankView">
            <img src="../../assets/img/search_blank.png" alt="" />
            <div>暂无搜索结果</div>
        </div>
    </div>
</template>

<script>
import DataList from "../Common/DataList";

export default {
    name: "Search",
    components: { DataList },

    data() {
        return {
            showView: 1, // 1 显示热门搜索 2 显示视频列表 3显示空白页面

            hotTagList: [],
            videoList: [],

            input: "",
            pageNum: 1
        };
    },
    created() {
        this.getHotTag();
    },
    mounted() {},
    methods: {
        // 获取热门搜索列表
        getHotTag() {
            this.$axios
                .post(
                    "/SD/globalSearch/getHotTag",
                    this.$qs.stringify({
                        version: "3.2.4"
                    })
                )
                .then(res => {
                    let data = res.data.result_data;
                    this.hotTagList = data;
                })
                .catch(err => {
                });
        },
        changePageNum(val) {
            this.pageNum = val;
            this.searchVideo(this.input);
        },
        // 上一页
        prev() {
            if (this.pageNum == 1) {
                // 当前为第一页
                this.$message({
                    message: "当前为第一页",
                    type: "warning"
                });
                return;
            }
            this.pageNum--;
            this.searchVideo(this.input);
        },
        // 下一页
        next() {
            if (this.videoList.length < 20) {
                // 当前为最后一页
                this.$message({
                    message: "当前为最后一页",
                    type: "warning"
                });
                return;
            }
            this.pageNum++;
            this.searchVideo(this.input);
        },

        searchClick() {
            if(this.input){
                this.pageNum = 1;
                this.searchVideo(this.input);
            }
        },
        searchVideo(keyword) {
            this.input = keyword
            this.$loading({
                background: "rgba(0, 0, 0, 0.7)",
                text: "拼命加载中"
            });

            // pageNum：第几页;  keyword:搜索内容 type：排序方式：1.最新，2最热
            this.$axios
                .post(
                    "/web/course/searchCourseList",
                    this.$qs.stringify({
                        pageNum: this.pageNum,
                        type: "1",
                        keyword: keyword
                    })
                )
                .then(res => {
                    let data = res.data;
                    this.videoList = [];
                    this.videoList = data.applys;

                    this.showView = this.videoList.length < 1 ? 3 : 2;
                    this.$loading().close();
                })
                .catch(err => {
                    this.showView = this.videoList.length < 1 ? 3 : 2;
                    this.$loading().close();
                });
        },

        toDetail(dic) {
            DataList
            if(dic.type == 1){

                this.$router.push({ path: "/video/"+dic.gid, params: { gid: dic.gid }});
            }else{
                this.$router.push({ path: "/classVideo", params: { gid: dic.gid }, query: { gid: dic.gid }});
            }
        }
    }
};
</script>

<style>
#Search {
    padding-top: 30px;
    margin: 0 auto;
    width: 1200px;
}

.inputDiv {
    margin-bottom: 27px;
}

.inputDiv .el-button {
    font-weight: normal;
}

.input {
    width: 80%;
    margin-right: 20px;
}
.pageBar {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 40px;
}

.pageBar span {
    display: flex;
    height: 100%;
    align-items: center;
    color: #f4f4f4;
    padding: 0 20px;
    margin: 0 24px;
    cursor: pointer;
}

/*  */
.redColor {
    font-weight: 400;
    color: #bb2e2c;
    line-height: 22px;
    font-size: 16px;
    margin-right: 16px;
}
.blackColor {
    font-weight: 400;

    color: #999999;
    line-height: 22px;
    font-size: 16px;
    margin-right: 16px;
}

.hotTitle {
    color: #171f24;
    font-weight: 600;
}
.hotList {
    margin-top: 20px;
}

.hotItem {
    display: inline-block;
    margin: 10px 0;
    width: 600px;

    font-size: 16px;
    color: #171f24;
    line-height: 22px;
}
.hotItem p {
    cursor: pointer;
    display: inline-block;
}

.subKeyword {
    margin-left: 16px;
    font-size: 14px;
    color: #999999;
}
/* 空白页 */
.blankView {
    width: 100%;
    height: 100%;
    text-align: center;

    color: #9b9b9b;
}

.blankView img {
    margin-top: 200px;
    margin-bottom: 20px;
}

.reset.el-button:hover {
    color: #5680FA;
    border:1px solid rgba(86, 128, 250,0.3);
}

.el-input /deep/ .el-input__inner:focus{
    border-color:#5680FA;
}
</style>
