import Vue from "vue";
import "./assets/js/elementUI.js";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/js/Vant";
import "./assets/css/reset.css";
// import "@vant/touch-emulator";
import App from "./App";
import router from "./router";
import qs from "qs";
import axios from "./tools/axios";
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;

import VueBus from "vue-bus";
Vue.use(VueBus);

Vue.config.productionTip = false;
new Vue({
    router,
    render: h => h(App)
}).$mount("#app");
