<template>
    <!-- 视频直播 -->
    <div class="live">
        <!-- <Popup v-if="!isLoad" /> -->

        <el-dialog :title="!isEnglish ? '法律声明' : 'Legal Notice'" :visible.sync="dialogVisible" width="60%"
            :before-close="handleClose">
            <template v-if="!isEnglish">
                <div class="changeBtn">
                    <span class="change" @click="changeLanguage">switch language</span>
                </div>
                <span class="mainText">
                    <p>
                        本页面所包含的内容均仅代表讲者本人或者该第三方的内容和观点，与骨今中外的立场无关。骨今中外不对其中包含或引用的信息的准确性、可靠性或完整性提供任何明示或暗示的保证。
                    </p>
                    <p>医疗健康人士注意：</p>
                    <p>
                        播放内容仅供医学教育目的，请勿将播放内容对任何他人传播。播放内容仅向受邀医疗健康人士开放。尽管术者/讲课顾问尽力在播放内容中提供最新的医疗教育、交流信息，这些内容不应被认为是治疗的标准，且不代表其他普遍治疗方法。
                    </p>
                    <p>非医疗健康人士注意：</p>
                    <p>
                        本播放内容仅向受邀的专业医疗健康人士开放，为您身心健康之考虑，请您酌情观看。
                    </p>
                    <p>
                        我们也建议在作出任何评价或治疗决定前请咨询其他专业人士并查阅其他参考资料。
                    </p>
                    <p>
                        对于任何因使用本页面内容而可能遭致的意外、疏忽、侵权及其造成的损失，骨今中外不承担任何法律责任。
                    </p>
                    <p>
                        如任何个人或其他媒体、网站欲转载使用本页面所载文章、视频、直播以及通过本页面使用或连接到的任何第三方的任何文字、图片或音、视频的，请与著作权人及骨今中外联系，并自负法律责任。
                    </p>
                </span>
                <span slot="footer" class="dialog-footer">
                    <el-button style="background-color: #5680FA;border-color: #5680FA" type="primary"
                        @click="dialogVisible = false">确 定</el-button>
                </span>
            </template>
            <template v-else>
                <div class="changeBtn">
                    <span class="change" @click="changeLanguage">切换语言</span>
                </div>

                <span class="mainText">
                    <p>
                        The content contained in this page only represents the content and opinions of the speaker or
                        the third party, and
                        has nothing to do with the position of GUJINZW. No warranty, reliability or integrality, is
                        given to the accuracy,
                        reliability or completeness of the information contained or quoted therein.
                    </p>
                    <p>Notice for medical health issue：</p>
                    <p>
                        The broadcast content is intended for medical education only. Please do not spread the broadcast
                        content to anyone
                        else. The broadcast is open only to invited medical and health professionals. Although the
                        surgeon/lecture speakers
                        are dedicated to provide up-to-date medical education and exchange information in the broadcast,
                        these info should
                        not be considered standard of care and do not represent other common treatments pathways.
                    </p>
                    <p>Notice for non-medical professionals：</p>
                    <p>
                        The broadcast content is intended for medical education only. For your physical and mental
                        health, please consider
                        well before your watch.
                    </p>
                    <p>
                        We also recommend that you consult other professionals and consult other reference materials
                        before making any
                        evaluation or treatment decision.
                    </p>
                    <p>
                        Any accident, negligence, infringement or loss that may result from the use of the contents of
                        this page, GUJINZW
                        will not take any legal responsibilities.
                    </p>
                    <p>
                        If any individual or any other media or website wants to reprint any text, picture, sound or
                        video of the article,
                        video, live broadcast or any third party used or connected to this page, please contact the
                        copyright owner and
                        GUJINZW, and take the legal responsibilities on your own.
                    </p>
                </span>
                <span slot="footer" class="dialog-footer">
                    <el-button style="background-color: #5680FA;border-color: #5680FA" type="primary"
                        @click="dialogVisible = false">Sure</el-button>
                </span>
            </template>
        </el-dialog>

        <div class="title"></div>

        <!-- 直播区 -->
        <div class="video">
            <!-- 播放按钮 -->
            <img class="btn_img" src="../../assets/img/sign-play.png" draggable="false" @click="playVideo"
                v-if="playShow" />
            <!-- 视频播放器 -->
            <Video ref="videoTmp" :play-show.sync="playShow" />
        </div>
        <!-- 导航区 -->
        <ul class="nav">
            <li :class="{ active: index == 0 }" @click="clickLiveAction()">直播互动</li>
            <li :class="{ active: index == 1 }" @click="index = 1">详情简介</li>
            <li :class="{ active: index == 2 }" @click="index = 2">查看更多</li>
        </ul>
        <!-- 内容区 -->
        <div class="content" name="fade" mode="out-in">
            <!-- 直播互动 -->
            <ChatView v-show="index === 0" ref="indexTmp" :avatar="avatar" :isSDK="isSDKReady" :loginFlag="loginFlag" />
            <!-- 详情简介 -->
            <div v-show="index === 1" class="content-about">
                <div v-if="!!introduction">
                    <div v-html="introduction"></div>
                    <div class="enrollPart" v-show="sequence == 3">
                        <template v-if="liveAuthStatus == 0">
                            <div class="enroll" @click="onClickRight">
                                报名观看
                            </div>
                        </template>
                        <template v-else-if="liveAuthStatus == 1">
                            <div class="enroll" @click="onClickRight">
                                已报名
                            </div>
                        </template>
                    </div>
                </div>

                <el-empty v-else description="暂无数据" />
            </div>
            <!-- 查看更多 -->
            <div v-show="index == 2" class="content-more">
                <img src="https://bonetoday.com/activity/img/live_more_1.png" draggable="false" />
                <img src="https://oss.orthoday.com/app-picture/2024/09/26/640cd702-9163-4237-bb12-9715eafbda13.jpg" draggable="false" />
            </div>
        </div>
    </div>
</template>

<script>
import TIM from "tim-js-sdk";
import tim from "@/assets/js/tim";
window.TIM = TIM;
window.tim = tim;
import TIMUploadPlugin from "tim-upload-plugin";
import ChatView from "../Common/ChatView";
import Popup from "../Common/PopUp";
import Video from "../Common/Video";
import { Bus } from "../../tools/bus";
export default {
    name: "Live",
    components: { Video, ChatView, Popup },
    data() {
        return {
            imgGid: "http://upload.orthoday.com/SDpic/common/picSelect?type=1&gid=",
            playShow: false /* 播放按钮控制 */,
            isLoad: true /* 加载完成 */,
            isEnglish: false,
            index: 1 /* 选中项下标 */,
            groupID: "",
            conversationID: "",
            introduction: "",
            isSDKReady: false /* 初始化状态 */,
            gid: "" /* 会议ID */,
            avatar: "",
            nickname: "",
            unionId: "",
            userId: "",
            token: "",
            title: "",
            isLogin: false /* 是否微信登录 */,
            loginFlag: false /* TIM用户登录 */,
            phoneModel: "" /* 手机型号 */,
            sequence: "",
            liveAuthStatus: "",
            confirmOrderDict: {},
            dialogVisible: true,
            timer: null,
            role: "", //判断是否是管理员'Admin'
            level: ""
        };
    },
    created() {

        this.userId = localStorage.getItem("userId") || "";
        this.token = localStorage.getItem("token") || "";
        let gId = this.$route.params.gId;
        if (gId) {
            this.gid = gId;
            this.initListener(); // tim初始化
            this.getLiveInfo(gId, this.unionId); // 获取直播详情
        }
    },

    methods: {
        changeLanguage() {
            this.isEnglish = !this.isEnglish;
        },
        handleClose(done) {
            done();
        },
        // 初始化tim事件
        initListener() {
            // 1、登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
            tim.on(TIM.EVENT.SDK_READY, this.onReadyStateUpdate);
            // 2、收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
            tim.on(TIM.EVENT.MESSAGE_RECEIVED, this.onMessageReceived);
            // 收到 SDK 进入 not ready 状态通知，此时 SDK 无法正常工作
            tim.on(TIM.EVENT.SDK_NOT_READY, this.onReadyStateUpdate, this);
            // 收到被踢下线通知
            tim.on(TIM.EVENT.KICKED_OUT, this.onKickedOut);
            // 收到 SDK 发生错误通知，可以获取错误码和错误信息
            tim.on(TIM.EVENT.ERROR, this.onError);
            tim.on(TIM.EVENT.MESSAGE_REVOKED, this.onMessageRevoked);
        },
        // 获取群详细资料
        getGroupProfile() {
            tim.getGroupProfile({ groupID: this.groupID }).then(res => {
                this.role = res.data.group.selfInfo.role;
                Bus.$emit("editRole", this.role);
            });
            tim.getMyProfile().then(res => {
                this.level = res.data.level;
                Bus.$emit("editLevel", this.level);
            });
        },
        // 0、获取直播详情数据
        getLiveInfo(gid, unionId) {
            sessionStorage.setItem("backLiveGid", gid || "");
            let params = {
                gid,
                disMobile: "PC",
                phoneModel: this.phoneModel,
                version: "1"
            };
            this.$axios
                .get("/web/live/getLiveDetail", { params })
                .then(res => {
                    if (res.data.code == 1) {
                        let data = res.data.result_data;
                        this.title = data.course.title;
                        this.isLoad = false; // 数据请求成功
                        // courseType：1、需要登录；2、不需要登录
                        // sequence 3需要购买才可观看
                        this.sequence = data.course.sequence;
                        // liveAuthStatus 0：未购买  1：购买
                        this.liveAuthStatus = data.course.liveAuthStatus;

                        if (data.course.sequence != "0") {
                            document.title = "用户登陆";
                            // 需要去登录 跳转登录页
                            if (this.userId && this.token) {
                                this.isLogin = false;
                            } else {
                                this.isLogin = true;
                                return;
                            }
                        }
                        // 免登陆
                        this.isLogin = false;

                        document.title = data.course.title;

                        let { course, user } = data;
                        this.groupID = course.chatroomId;
                        // 传groupID
                        this.$refs.indexTmp.groupID = course.chatroomId;
                        this.conversationID = `GROUP${course.chatroomId}`;
                        // TIM Login
                        let identifier = user.identifier,
                            usersig = user.usersig;
                        // TIM登录
                        this.$nextTick(() => {
                            this.timLogin(identifier.toLowerCase(), usersig, course.chatroomId);
                        });


                        let liveTime = Number(course.liveTime);
                        let avatar = "";
                        if (course.avatar != "") {
                            avatar = `https://www.orthoday.com/SDpic/common/picSelect?gid=${course.avatar}`;
                        }

                        // 确认订单需要的参数
                        this.confirmOrderDict = {
                            gid: this.gid,
                            livePic: course.livePic,
                            title: course.title,
                            author: course.expertNames,
                            price: course.price
                        };

                        let wn = course.watchNumber["type"] == "1" ? course.watchNumber["content"] : course.watchNumber;

                        // 视频模块需要的参数
                        let sendInfo = {
                            userId: this.userId,
                            token: this.token,
                            gid: gid,
                            appointStatus: course.appointStatus,
                            avatar: avatar,
                            title: course.title,
                            watchNumber: wn,
                            liveTime: liveTime,
                            liveUrl: course.liveUrl,
                            liveType: course.liveType,
                            sequence: this.sequence,
                            liveAuthStatus: this.liveAuthStatus
                        };
                        /* 初始化video数据 */
                        this.$refs.videoTmp.initVideo(sendInfo);
                        // 详情简介
                        this.introduction = course.introduction;
                    } else if (res.data.code == 1102) {
                        this.isLoad = false;
                        this.isLogin = true;
                        this.$message.warning(`数据获取失败:${res.data.message}`);
                        if (this.userId == "" || this.token == "") {
                            this.$router.push("/login");
                        }
                    }
                })
                .catch(err => {
                    this.isLoad = false;
                    this.$message.error("数据获取失败");
                });
        },
        // 点击直播互动 加载聊天室
        clickLiveAction() {
            this.index = 0;
            this.$refs.indexTmp.to_bottom();
        },
        /** 1、调用登录
         * 用户 ID
         * @param {Stirng} userID
         * 用户签名
         * @param {Stirng} userSig
         * 聊天室 ID
         * @param {Stirng} chatroomId
         *  */
        timLogin(userID, userSig, chatroomId) {
            tim.registerPlugin({ "tim-upload-plugin": TIMUploadPlugin }); // 在 login 前调用，以支持文件上传腾讯云对象存储
            tim.login({ userID, userSig })
                .then(imResponse => {
                    this.loginFlag = true;
                    if (imResponse.data.repeatLogin === true) {
                        setTimeout(() => {
                            this.getGroupProfile();
                        }, 500);
                        this.$refs.indexTmp.getMessageList(this.groupID, true); // 获取信息列表
                    }
                })
                .catch(imError => {
                    console.warn("login error:", imError); // 登录失败的相关信息
                });
        },
        /**
         * 2、申请加聊天室
         * */
        applyJoinGroup(groupID) {
            // 加入聊天室
            tim.joinGroup({ groupID, type: TIM.TYPES.GRP_MEETING })
                .then(imResponse => {
                    let { status } = imResponse.data;
                    switch (status) {
                        case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
                            break;
                        case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
                            break;
                        case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已在群中
                            break;
                        default:
                            break;
                    }
                    this.$refs.indexTmp.getMessageList(groupID, true); // 获取群历史记录
                })
                .catch(imError => {
                    if (imError.code == 10013) {
                        this.$refs.indexTmp.getMessageList(groupID, true); // 已是群成员或已在群中
                    }
                });
        },
        // 3、登录监听事件
        onReadyStateUpdate({ name }) {
            const isSDKReady = name === TIM.EVENT.SDK_READY ? true : false;
            if (isSDKReady) {
                this.isSDKReady = true;
                this.applyJoinGroup(this.groupID);
                setTimeout(() => {
                    this.getGroupProfile();
                }, 500);
            }
        },
        // 被踢出
        onKickedOut(event) {
            this.loginFlag = false;
        },
        // 监听消息被撤回
        onMessageRevoked(event) {
            var index = this.$refs.indexTmp.messageList.findIndex(item => {
                item.sequence == event.data[0].sequence;
            });
            this.$refs.indexTmp.messageList.splice(index, 1);
        },
        // SDK内部出错
        onError({ data }) {
            if (data.message !== "Network Error") {
            }
        },
        // 改变群通知
        changeNotice(item) {
            let time = item.time * 1000;
            let { newGroupProfile, operationType } = item.payload;
            let notification = newGroupProfile["notification"] || "";
            let introduction = newGroupProfile["introduction"] || "";
            if (operationType == 6) {
                if (!!notification) {
                    // 判断直播是否结束
                    let isLiveEnd = notification.includes("直播已结束");
                    if (isLiveEnd) {
                        this.$refs.videoTmp.stopLive(); // 直播已结束
                    }
                    let time = item.time ? item.time : 0;
                    let notice = { time, notification }; // 更新公告
                    this.$refs.indexTmp.notice = notice;
                }
            }
        },
        // 收到新消息
        onMessageReceived(event) {
            let groupID = this.groupID,
                conversationID = "GROUP" + groupID,
                data = event.data,
                messageList = [],
                flag = this.$refs.indexTmp.getScrollTop();
            data.map(item => {
                if (item.conversationID == conversationID) {
                    switch (item.type) {
                        case TIM.TYPES.MSG_TEXT: // 群消息
                            messageList.push(item);
                            if (flag) {
                                setTimeout(() => {
                                    this.$refs.indexTmp.to_bottom();
                                }, 300);
                            }
                            break;
                        case TIM.TYPES.MSG_GRP_TIP: // 群提示
                            this.changeNotice(item);
                            break;
                        case TIM.TYPES.MSG_GRP_SYS_NOTICE: // 群公告
                            break;
                        default:
                            break;
                    }
                } else if (item.to == groupID) {
                    let { operationType, userDefinedField } = item.payload;
                    if (operationType == 255 && !!userDefinedField) {
                        var temp = JSON.parse(userDefinedField);
                        let wn = temp["type"] == 1 ? temp["content"] : userDefinedField;

                        this.$refs.videoTmp.videoInfo.watchNumber = wn; //观看人数
                    }
                }
            });
            // 聊天信息
            if (messageList.length) {
                this.$refs.indexTmp.messageList.push(...messageList);
            }
        },
        // 获取地址栏参数
        getQueryString(n) {
            let r,
                reg = new RegExp("(^|&)" + n + "=([^&]*)(&|$)");
            r = window.location.search.substr(1).match(reg);
            return r ? decodeURI(r[2]) : null;
        },
        // 暂停视频播放
        playVideo() {
            if (this.sequence == 3 && this.liveAuthStatus == 0) {
                this.$message("此直播为付费后可观看，请先付费报名观看！");
                return;
            }

            this.$refs.videoTmp.playVideo();
        },
        // 关闭页面退群 Android
        unloadHandler() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
            if (isAndroid) {
                !!this.groupID && tim.quitGroup(this.groupID);
            }
            tim.logout();
        },
        // 关闭页面退群 iOS
        pagehideHandler() {
            let u = navigator.userAgent;
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isIOS) {
                !!this.groupID && tim.quitGroup(this.groupID);
            }
            tim.logout();
        },

        //  支付
        onClickRight() {
            //判断 userId 和 token  为空就跳转登录
            if (this.userId == "" || this.token == "") {
                this.$router.push("/login");
            } else {
                return;
            }

            // 3需要购买才可观看       0：未购买
            if (this.sequence == "3" && this.liveAuthStatus == "0") {
                sessionStorage.setItem("confirmOrderDict", JSON.stringify(this.confirmOrderDict));
                this.$router.push({
                    name: "ConfirmOrder",
                    params: this.confirmOrderDict
                });
            }
        }
    },
    beforeDestroy() {
        if (this.isSDKReady && this.role != "Admin") {
            tim.quitGroup(this.groupID)
                .then(imResponse => {
                })
                .catch(imError => {
                });
            // 退群
        }
        tim.logout();
    },
    destroyed() {

        clearInterval(this.timer);
        this.timer = null;

        window.removeEventListener("pagehide", this.pagehideHandler, false);
        window.removeEventListener("unload", this.unloadHandler, false);
        /* 销毁聊天实例 */
        tim.off(TIM.EVENT.SDK_READY, this.onReadyStateUpdate);
        tim.off(TIM.EVENT.MESSAGE_RECEIVED, this.onMessageReceived);
        tim.off(TIM.EVENT.SDK_NOT_READY, this.onReadyStateUpdate);
        tim.off(TIM.EVENT.KICKED_OUT, this.onKickedOut);
        tim.off(TIM.EVENT.ERROR, this.onError);
        tim.off(TIM.EVENT.MESSAGE_REVOKED, this.onMessageRevoked)
        tim.logout()
    }
};
</script>

<style>
.el-dialog__wrapper {
    z-index: 20000 !important;
}

.el-dialog__header {
    text-align: center;
}

.el-dialog__body {
    line-height: 20px !important;
    text-align: center;
}

.mainText {
    text-align: left;
    margin: 20px;
}

.changeBtn {
    /* background-color: #ff0; */
}

.change {
    /* background-color: #0ff; */
    padding: 4px 6px;
    border: solid rgb(187, 46, 35) 1px;
    border-radius: 18px;
    cursor: pointer;
}

.el-dialog__footer {
    text-align: center;
}

.live {
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    flex-direction: column;
    font-family: "PingFangSC-Regular", "PingFang SC";
    height: 100%;
    overflow-y: auto;
    background: #f9f9f9;
    -webkit-overflow-scrolling: touch;

    /* background-color: #fff; */
    margin: 0 70px;
    min-height: 500px;
}

/* .popdiv {
  width: 100%;
  height: 100%;
  background-color: #f0f;
  margin: 0;
  padding: 0;
} */

.title {
    width: 1000px;
    margin: 0 auto;
    height: 30px;
    line-height: 30px;
}

.nav {
    /* padding: 0 10px; */
    display: flex;
    /* @include flex; */
    justify-content: space-between;
    align-items: center;
    height: 38px;
    background: #fff;
    width: 1000px;
    margin: 0 auto;
}

.nav li {
    display: block;
    width: 120px;
    line-height: 35px;
    font-size: 15px;
    letter-spacing: 2px;
    color: #171f24;
    text-align: center;
}

.nav li.active {
    color: #5680FA;
    text-shadow: 0px 2px 3px rgba(229, 243, 255, 1);
}

.nav li:hover {
    cursor: pointer;
}

.content {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    width: 1000px;
    margin: 0 auto;
    min-height: 600px;
}

.content-about {
    height: auto;
    font-size: 15px;
    margin-bottom: 30px;
    /* background-color: #998ffc; */
}

.content-about img {
    width: 100%;
    max-width: none !important;
}

.content-more {
    /* width: 100vw; */
    margin-bottom: 30px;
}

.content-more img {
    width: 100%;
}

.enrollPart {
    background-color: #fff;
    width: 100%;
    height: 50px;
    position: relative;
    bottom: 0;
    /* margin: 0 70px; */
}

.enroll {
    background-color: #198ffc;
    height: 35px;
    margin: 7px 10px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    line-height: 35px;
    border-radius: 6px;
}

/* play */
img.btn_img {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10050;
}
</style>

<style scoped>
div.live>>>div.van-dialog {
    top: 48%;
    z-index: 12346 !important;
}

.el-empty {
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
</style>
