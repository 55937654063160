import Vue from "vue";
import VueRouter from "vue-router";

import Member from "@/components/Member/Member";
import Register from "@/components/Account/Register";
import NotFound from "@/components/NotFound/NotFound";
import Live from "@/components/Video/Live";
import LiveList from "@/components/Video/LiveList";
import BoneAdd from "@/components/Bone/BoneAdd";
import Search from "@/components/Search/Search";

Vue.use(VueRouter);

export default new VueRouter({
    mode: "history",
    routes: [
        { path: "/home", redirect: "/" },
        {
            path: "/",
            name: "Home",
            component: () => import("@/components/Home/Home")
        },
        {
            path: "/video",
            component: () => import("@/components/Video/Video"),
            children: [
                {
                    path: "",
                    name: "VideoList",
                    component: () => import("@/components/Video/VideoList")
                },
                {
                    path: "/video/:gId",
                    name: "VideoDetail",
                    component: () => import("@/components/Video/VideoDetail")
                }
            ]
        },
        {
            path: "/course",
            component: () => import("@/components/Course/Course"),
            children: [
                {
                    path: "",
                    name: "CourseList",
                    component: () => import("@/components/Course/CourseList")
                },
                {
                    path: "/classVideo",
                    name: "CourseDetail",
                    component: () => import("@/components/Course/CourseDetail")
                }
            ]
        },
        { path: '/wx_auth', name: 'CourseDetail', component: () => import("@/components/Course/CourseDetail") },

        {
            path: "/train",
            name: "Train",
            component: () => import("@/components/Train/Train"),
            meta: { keepAlive: true }
        },
        { path: "/member", name: "Member", component: Member },
        { path: "/login", name: "Login", component: () => import("@/components/Account/Login") },
        { path: "/register", name: "Register", component: Register },
        {
            path: "/user",
            name: "UserInfo",
            component: () => import("@/components/Account/UserInfo")
        },
        { path: "*", name: "NotFound", component: NotFound },
        {
            path: "/live/:gId",
            name: "Live",
            component: Live
        },
        {
            path: "/liveList",
            name: "LiveList",
            component: LiveList
        },
        { path: "/boneAdd", name: "Bone", component: BoneAdd },
        { path: "/search", name: "Search", component: Search },
        {
            path: "/myVideo",
            name: "MyVideo",
            component: () => import("@/components/Video/MyVideo")
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, resolve, reject) {
    if (resolve || reject) return originalPush.call(this, location, resolve, reject);
    return originalPush.call(this, location).catch(e => {});
};
