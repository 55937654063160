import { render, staticRenderFns } from "./PopUpWin.vue?vue&type=template&id=f505de4c&scoped=true"
import script from "./PopUpWin.vue?vue&type=script&lang=js"
export * from "./PopUpWin.vue?vue&type=script&lang=js"
import style0 from "../../assets/css/pop-up.css?vue&type=style&index=0&id=f505de4c&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f505de4c",
  null
  
)

export default component.exports