<template>
    <div class="home">
        <!-- 信息通知 -->
        <div class="notice-list" v-if="!!notice.notification">
            <div class="notice-top">
                <span class="n-nick">
                    <img src="../../assets/img/ding.png" draggable="false" />
                    管理员公告
                </span>
                <span class="time">{{ changeTime(notice.time) }}</span>
            </div>
            <!-- 群公告 -->
            <p class="n-notice">
                <span>{{ notice.notification }}</span>
            </p>
        </div>
        <!-- 聊天列表 -->
        <div class="list" ref="listBox" @scroll="onScroll">
            <!-- 聊天数据 -->
            <ul v-if="messageList.length > 0" class="list-warp" ref="listScroll">
                <template v-for="(item, index) in messageList">
                    <li :key="item.random + index" v-if="!item.isRevoked">
                        <img v-if="!checkAdmin(item)" :src="item.avatar ? item.avatar : defaultAvatar" class="avatar" />
                        <div class="text-right">
                            <div class="header">
                                <div class="AdminNick" v-if="checkAdmin(item)"><span>小助手</span> 杨柳</div>
                                <span class="nick" v-else>{{ item.nick ? item.nick : "我" }}</span>
                                <span class="time">{{ changeTime(item.time) }}</span>
                            </div>
                            <p v-if="item.type == 'TIMTextElem'" class="msg" v-html="handelText(item.payload.text)"></p>
                            <el-image
                                class="messageImg Cursor"
                                v-show="item.type == 'TIMImageElem'"
                                :src="item.payload.text"
                                :preview-src-list="[item.payload.text]"
                            >
                            </el-image>
                        </div>
                    </li>
                </template>
            </ul>
            <!-- 暂无数据 -->
            <img v-else src="../../assets/img/no_msg.png" class="no_msg" draggable="false" />
        </div>
        <!-- 底部输入框 -->
        <div class="footer" v-show="!show">
            <span class="callMsg" @click="callMsg">我来说两句...</span>
        </div>
        <!-- pop弹窗 -->
        <div v-show="show">
            <el-input
                type="textarea"
                :rows="3"
                maxlength="100"
                placeholder="请输入内容"
                show-word-limit
                v-model="inputValue"
                @keyup.enter.native="handleKeyUp"
            >
            </el-input>
            <div class="pop_btn">
                <el-button type="info" size="mini" @click="loading = show = false">&nbsp;&nbsp;取 消&nbsp;&nbsp;</el-button>
                <el-button style="background-color: #5680FA;border-color: #5680FA" type="primary" size="mini" :loading="loading" @click="sendMsg">&nbsp;&nbsp;发 送&nbsp;&nbsp;</el-button>
            </div>
        </div>
    </div>
</template>

<script>
var timers;
import { Bus } from "../../tools/bus";
export default {
    name: "Home",
    props: ["avatar", "isSDK", "loginFlag"],
    data() {
        return {
            show: false, // 评论弹窗
            defaultAvatar:
                "http://oss.medkr.com/app-picture/2015/12/15/Big/40aaa107-1fad-4e62-8438-09966b77368b.jpg?x-oss-process=image/resize,m_lfit,h_500,w_500",
            wxavatar: "",
            loading: false,
            inputValue: "",
            messageList: [],
            notifyList: [],
            isLoadMore: false,
            notice: { time: 0, notification: "" },
            list: [],
            groupID: "",
            nextReqMessageID: "", // 下拉标识
            isCompleted: "", // 下拉是否结束
            innerHight: 0, // 滚动的高度
            imgurl: "",
            imgList: ["http://example.com/image1.jpg", "http://example.com/image2.jpg", "http://example.com/image3.jpg"],
            role: "",
            level: ""
        };
    },
    watch: {
        avatar(nv, ov) {
            this.wxavatar = nv;
        }
    },
    computed: {
        // 判断是否是管理员发言
        checkAdmin() {
            return item => {
                let from = item.from && item.from.indexOf("bonetoday") == -1 ? false : true;
                if (from || item.cloudCustomData == "Admin" || (item.cloudCustomData && item.cloudCustomData.indexOf("Admin") != -1)) {
                    return true;
                } else {
                    return false;
                }
            };
        },
        changeTime() {
            return val => {
                if (val == 0) return;
                const date = new Date(val * 1000);
                let HH = date.getHours(),
                    mm = date.getMinutes(),
                    ss = date.getSeconds();
                HH = HH < 10 ? "0" + HH : HH;
                mm = mm < 10 ? "0" + mm : mm;
                ss = ss < 10 ? "0" + ss : ss;
                return HH + ":" + mm + ":" + ss;
            };
        },
        // 处理文本消息带换行格式
        handelText() {
            return val => {
                let htmlStr = "",
                    valArr;
                if (val && val.indexOf("\n") != -1) {
                    valArr = val.split("\n");
                    valArr.forEach(item => {
                        htmlStr = htmlStr + item + "<br/>";
                    });
                    return htmlStr;
                } else {
                    return val;
                }
            };
        }
    },
    mounted() {
        let wxavatar = sessionStorage.getItem("avatar");
        !!wxavatar && (this.wxavatar = wxavatar);
        Bus.$on("editRole", val => {
            this.role = val;
        });
        Bus.$on("editLevel", val => {
            this.level = val;
        });
    },
    methods: {
        onScroll() {
            let lb_dom = this.$refs.listBox,
                lb_SH = lb_dom.scrollHeight, // 总高度
                lb_OH = lb_dom.offsetHeight, // 容器的高度
                lb_ST = lb_dom.scrollTop; // 滚动的高度
            if (lb_SH == lb_OH + lb_ST) {
                this.isLoadMore = false;
            }

            if (lb_ST == 0) {
                this.innerHight = this.$refs.listScroll.scrollHeight; // 滚动加载前的数据高度
                let groupID = this.groupID;
                this.getMessageList(groupID, false);
            }
        },
        // * 获取漫游消息
        getMessageList(groupID, isInit) {
            if (this.isCompleted) {
                this.isLoadMore = false; // 是否是加载更多数据
                this.$message("已经没有更多的历史消息了哦");
                return;
            }

            if (isInit == false) {
                this.isLoadMore = true; // 是否是加载更多数据
            }

            let group_id = groupID || this.groupID;
            let params = {
                conversationID: "GROUP" + group_id,
                nextReqMessageID: this.nextReqMessageID,
                count: 15
            };
            // 从后台数据库获取历史消息
            this.$axios
                .get("/SD/course/getHistoryMsg", { params })
                .then(res => {
                    if (res.data.code == 1) {
                        let { isCompleted, messageList, nextReqMessageID } = res.data.result_data;
                        if (messageList.length) {
                            this.messageList.unshift(...messageList);
                            this.isLoadMore = false;
                        }
                        this.$nextTick(() => {
                            this.$refs.listBox.scrollTop = 400;
                        });
                        this.nextReqMessageID = nextReqMessageID || "";
                        this.isCompleted = isCompleted;
                    }
                    let promise = tim.getMessageList(params);
                    promise.then(imResponse => {
                        let messageList = imResponse.data.messageList; // 消息列表
                        let mList = [],
                            nList = [];
                        // 处理群消息
                        messageList.map(item => {
                            switch (item.type) {
                                // case TIM.TYPES.MSG_TEXT: // 聊天消息
                                //     mList.push(item);
                                //     break;
                                case TIM.TYPES.MSG_GRP_TIP: // 群通知
                                    if (isInit == true) nList.unshift(item);
                                    break;
                                default:
                                    break;
                            }
                        });

                        if (mList.length) {
                            let oldMsg = this.messageList;
                            this.messageList = [...mList, ...oldMsg];
                        }

                        if (nList.length) {
                            let n_item = nList[0],
                                operationType = n_item.payload.operationType,
                                { notification } = n_item.payload.newGroupProfile;

                            if (operationType == 6 && notification) {
                                let time = n_item.time ? n_item.time : 0;
                                this.notice = { time, notification };
                            }
                        }
                    });
                })
                .catch(err => {
                    this.isLoadMore = false;
                });
        },
        // 唤起消息弹窗
        callMsg() {
            this.inputValue = "";
            this.show = true;
        },
        // 换行或发送
        handleKeyUp(event) {
            if (event.key === "Enter" && event.shiftKey) {
                this.sendMsg(); // 判断为提交行为
            } else {
                return;
            }
        },
        // 发送信息
        sendMsg() {
            let val = this.inputValue.trim();
            if (val == "") {
                this.$message("发送内容不能为空！");
                return;
            }
            if (this.loading) return;
            this.loading = true;
            this.isLoadMore = false;

            let data = { text: this.inputValue };
            this.$axios
                .post("/SD/course/checkMsg", this.$qs.stringify(data))
                .then(res => {
                    if (res.data.code == 1) {
                        let data = res.data.result_data;
                        this.sendMessage(data.text, data.originalText);
                    }
                })
                .catch(err => {
                    this.inputValue = "";
                    this.loading = false;
                    this.$message.error("发送失败---:", err);
                });
        },
        // 发送消息&&记录到后台
        sendMessage(text, originalText) {
            // 记录消息
            const markMessage = data => {
                this.$axios
                    .post("/SD/course/recordMsg", this.$qs.stringify(data))
                    .then(res => {
                        if (res.data.code == 1) {
                            this.inputValue = ""; // 发送成功
                            this.loading = false;
                            this.messageList.push(data);
                            this.to_bottom();
                        }
                    })
                    .catch(err => {
                    });
            };
            const myData = {
                level: this.level, //是否是会员
                role: this.role //Admin
            };
            // 1、创建消息实例，接口返回实例可以上屏
            const Message = tim.createTextMessage({
                to: this.groupID,
                conversationType: TIM.TYPES.CONV_GROUP, // TIM.TYPES.CONV_GROUP（群组会话）
                payload: { text: text },
                cloudCustomData: JSON.stringify(myData)
            });
            // 2、发送消息
            tim.sendMessage(Message)
                .then(imResponse => {
                    let message = imResponse.data.message;
                    message.text = text;
                    message.originalText = originalText;
                    // markMessage(message);//暂时不调后台
                    this.inputValue = ""; // 发送成功
                    this.loading = false;
                    this.messageList.push(message);
                    this.to_bottom();
                })
                .catch(imError => {
                    this.$message.error("发送失败啦");
                });
        },
        // 置底
        to_bottom() {
            this.$nextTick(() => {
                this.$refs.listBox.scrollTop = 99999;
            });
        },
        // 判断起初滚动条高度，用于接收新消息时置底
        getScrollTop() {
            let lb_dom = this.$refs.listBox,
                lb_SH = lb_dom.scrollHeight, // 总高度
                lb_OH = lb_dom.offsetHeight, // 容器的高度
                lb_ST = lb_dom.scrollTop; // 滚动的高度
            if (lb_SH - lb_OH - lb_ST < 50) {
                return true;
            }
        }
    },
    beforeDestroy() {
        clearInterval(timers);
    }
};
</script>

<style src="../../assets/css/chat.css"></style>
<style lang="less">
.footer {
    .callMsg {
        color: #5680FA;
    }
    .callMsg:hover {
        cursor: pointer;
    }
}

.van-cell {
    .van-field__body {
        display: flex !important;
        align-items: center;
        .van-field__control {
            flex: 1;
        }
    }
}
</style>
<style lang="less" scoped>
.home {
    height: 700px;
}
.messageImg {
    width: 200px;
}
.AdminNick {
    color: #054fff;
    font-size: 16px;
    span {
        font-size: 14px;
        padding: 4px;
        display: inline-block;
        background: #568ffc;
        color: #fff;
        padding-right: 12px;
        border-radius: 0 0 20px 0;
    }
}
</style>
