<template>
    <div id="app">
        <Header />
        <Content />
        <Footer />
    </div>
</template>

<script>
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";

export default {
    name: "App",
    components: { Header, Content, Footer }
};
</script>

<style>
#app {
    width: 100%;
    min-width: 1200px;
    background-color: #fff;
}

#content {
    margin-top: 70px;
    width: 100%;
    min-height: 700px;
    background-color: #fafafa;
}
.Cursor:hover {
    cursor: pointer;
}
</style>
