<template>
    <div id="mask" v-show="isPop">
        <div class="popWin">
            <div class="close">
                <img src="../../assets/img/close.png" draggable="false" @click="$emit('closePop')" />
            </div>
            <div class="popImg">
                <VueQr
                    draggable="false"
                    :correctLevel="3"
                    :dotScale="1"
                    :logoSrc="logo"
                    :margin="15"
                    :size="256"
                    :text="codeUrl"
                />
            </div>
            <p class="tips">{{tipWord}}</p>
        </div>
    </div>
</template>

<script>
import VueQr from "vue-qr";

export default {
    name: "PopUpWin",
    props: ["isPop", "tipWord", "codeUrl"],
    data() {
        return {
            logo: require("../../assets/img/v_logo.png")
        };
    },
    components: { VueQr }
};
</script>

<style scoped src="../../assets/css/pop-up.css"></style>