<template>
    <div id="notFound">
        <div class="page404">
            <img src="../../assets/img/404.png" draggable="false" />
        </div>
        <p class="tips">
            <router-link to="/" tag="span">&lt;-&nbsp;返回首页</router-link>
        </p>
    </div>
</template>

<script>
export default {
    name: "NotFound",
    data() {
        return {};
    }
};
</script>

<style scoped>
#notFound {
    height: 700px;
    background-color: #fff;
    overflow: hidden;
}

.page404 {
    margin: 70px auto 10px;
    width: 550px;
    height: 413px;
}

p.tips {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
}

p.tips span {
    cursor: pointer;
    letter-spacing: 2px;
}

p.tips span:hover {
    color: #3af;
}
</style>