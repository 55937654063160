<template>
    <div id="member">
        <PopUpWin :isPop.sync="isShow" :tipWord="tips" :codeUrl="url" @closePop="isShow=false" />
    </div>
</template>

<script>
import PopUpWin from "../Common/PopUpWin";

export default {
    name: "Member",
    data() {
        return {
            isShow: true,
            tips: "微信扫码查看年会员详情",
            url: "http://app.orthoday.com/download_app.html"

            // url:
            //     "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc8fb10081c4a9f01&redirect_uri=http://app.orthoday.com/vipDescribe.html&response_type=code&scope=snsapi_base&state=123&connect_redirect=1#wechat_redirect"
        };
    },
    components: { PopUpWin }
};
</script>

<style scoped>
#member {
    width: 100%;
    background-color: #fff;
}
</style>