import axios from "axios";

const service = axios.create({
    timeout: 0
});
service.base;

// request 请求拦截
service.interceptors.request.use(
    config => {
        const token = localStorage.getItem("token") || "",
            userGid = localStorage.getItem("userId") || "";
        config.headers["token"] = token;
        config.headers["userId"] = userGid;
        return config;
    },
    err => {
        Promise.reject(err);
    }
);

// reponse 响应拦截
service.interceptors.response.use(
    response => {
        return response;
    },
    err => {
        return Promise.reject(err);
    }
);

export default service;
