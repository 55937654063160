<template>
    <div class="authentForm">
        <div class="employee-card" v-if="from == 'user'">
            <p class="tips" style="display: flex; align-items: flex-start">
                <i style="color: #F56C6C; font-size: 14px;font-style: normal;margin-right: 2px;">*</i>
                <span>工作证/执业证/医师资格证任选其一即可</span>
            </p>
            <div class="img-box">
                <div class="box-right">
                    <el-upload
                        
                        :disabled="isConFlag"
                        action="#"
                        class="avatar-uploader"
                        :data="{ picType: 'jpg' }"
                        :show-file-list="false"
                        :http-request="
                            (file) => {
                                return beforeAvatarUpload(file.file)
                            }
                        "
                        :limit="1"
                    >
                        <img v-if="reg.imageUrl" @click="uploadImg" :src="reg.imageUrl" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <p v-if="imageTip" style="color: #F56C6C; margin-top: 6px; font-size: 12px;"> 请上传认证信息 </p>
                </div>
            </div>
        </div>
        <div class="con-info">
            <el-form ref="conForm" label-width="100px" :model="reg" :rules="regRules" :disabled="isConFlag">
                <el-form-item label="姓名：" prop="name">
                    <el-input size="medium" placeholder="请输入姓名" v-model="reg.name"></el-input>
                </el-form-item>
                <el-form-item label="性别：" prop="gender">
                    <el-select size="medium" v-model="reg.gender" placeholder="请选择性别" style="width:100%;">
                        <el-option v-for="(item, index) in genderArr" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="医院：" prop="s_hospital">
                    <el-cascader
                        :class="reg.w_hospital&&!isConFlag?'hasData':''"
                        size="medium"
                        :options="hospitalArr"
                        v-model="reg.s_hospital"
                        :placeholder="reg.w_hospital?reg.w_hospital:'请选择医院'"
                        :props="p_obj"
                        @change="choiceHospital"
                        clearable
                        style="width:100%;"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="填写医院：" prop="w_hospital" v-if="show_hospital">
                    <el-input
                        size="medium"
                        placeholder="如未找到医院请手动填写"
                        v-model="reg.w_hospital"
                    ></el-input>
                </el-form-item>
                <el-form-item label="科室：" prop="department">
                    <el-input size="medium" placeholder="请输入科室" v-model="reg.department"></el-input>
                </el-form-item>
                <el-form-item label="地区：" prop="code">
                    <el-cascader 
                        :class="reg.localName&&!isConFlag?'hasData':''"
                        clearable
                        size="medium"
                        v-model="reg.code"
                        separator = ','
                        :placeholder="reg.localName?reg.localName:'请选择地区'"
                        style="width:100%;"
                        :props="props">
                    </el-cascader>
                </el-form-item>
                <el-form-item label="职称：" prop="jobTitle">
                    <el-select size="medium" v-model="reg.jobTitle" placeholder="请选择职称" style="width:100%;">
                        <el-option v-for="(item, index) in jobList" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="专业：" prop="major">
                    <el-select size="medium" v-model="reg.major" placeholder="请选择专业" style="width:100%;">
                        <el-option v-for="(item, index) in majorList" :key="index" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="认证信息：" prop="imageUrl" v-if="from == 'register'" class="upload">
                    <p style="color: #999999;font-weight: 300;margin-bottom: 10px;">工作证/执业证/医师资格证任选其一即可</p>
                    <el-upload
                        action="#"
                        class="avatar-uploader"
                        :data="{ picType: 'jpg' }"
                        :disabled="isConFlag"
                        :show-file-list="false"
                        :http-request="
                            (file) => {
                                return beforeAvatarUpload(file.file)
                            }
                        "
                        :limit="1"
                    >
                        <img v-if="reg.imageUrl" @click="uploadImg" :src="reg.imageUrl" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-button :disabled="false" type="primary" size="medium" style="width:100%;background-color: #5680FA;border-color: #5680FA" @click="userConfirm">{{btnText}}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    name: "AuthentForm",
    props: ['from','details','btnText','isConFlag'],
    data() {
        return {
            reg:{},
            areaArr: [],
            hospitalArr: [],
            imageTip: false,
            localLabelName: "",
            show_hospital:false,
            pic: "https://www.orthoday.com/SDpic/common/picSelect?gid=",
            genderArr: [
                { value: 1, label: "男" },
                { value: 2, label: "女" }
            ],
            props:{
                lazy: true,
                lazyLoad: this.lazyLoad
            },
            p_obj: {
                checkStrictly: true,
                leaf: "code",
                expandTrigger: "hover",
                label: "name",
                value: "code",
                children: "cities"
            },
            jobList: [
                { value: "主任医师", label: "主任医师" },
                { value: "副主任医师", label: "副主任医师" },
                { value: "主治医师", label: "主治医师" },
                { value: "住院医师", label: "住院医师" },
                { value: "实习医师", label: "实习医师" },
                { value: "医学生", label: "医学生" },
                { value: "其他", label: "其他" }
            ],
            majorList:[
                { value: "创伤", label: "创伤" },
                { value: "关节", label: "关节" },
                { value: "脊柱", label: "脊柱" },
                { value: "运医", label: "运医" },
                { value: "小儿", label: "小儿" },
                { value: "颅颌面", label: "颅颌面" },
                { value: "其他", label: "其他" }
            ],
            regRules: {
                name: [{required: true,message: "请填写用户名",trigger: 'blur'}],
                w_hospital: [{required: true,message: "请填写医院",trigger: 'blur'}],
                department: [{ required: true, message: "请填写科室", trigger: 'blur' }],
                major: [{required: true,message: "请选择专业",trigger: "change"}],
                gender: [{required: true,message: "请选择性别",trigger: "change"}],
                jobTitle: [{required: true,message: "请选择职称",trigger: "change"}],
                imageUrl: [{ required: true, message: "请上传认证信息", trigger: "change" }],
                s_hospital: [
                    {
                        required: true,
                        trigger: "change",
                        type: "array",
                        validator: (rule, value, callback) => {
                            this.reg.w_hospital||value.length ? callback() : callback(new Error("请选择医院"));
                        }
                    }
                ],
                code: [
                    {
                        required: true,
                        trigger: "change",
                        type: "array",
                        validator: (rule, value, callback) => {
                            this.reg.localName||value.length ? callback() : callback(new Error("请选择地区"));
                        }
                    }
                ]
            }
        };
    },
    created() {
        this.reg = this.details
        this.getArea();
    },
    methods: {
        // 选择医院地区
        choiceHospital(v) {
            if (v.length) {
                v.length < 3 ? (this.show_hospital = true,this.reg.w_hospital = '') : (this.show_hospital = false);
                this.getArea(v);
            }else{
                this.reg.w_hospital = ''
            }
        },
        // 获取医院地区
        async getArea(val) {
            if (!val || val.length == 1) {
                let code = "",
                    level = "1";
                val && val.length == 1 ? ((code = val[0]), (level = "2")) : "";

                await this.$axios
                    .post("/web/common/areaSelect", this.$qs.stringify({ code, level }))
                    .then(res => {
                        let data = res.data;
                        if (data.code == 1) {
                            if (!val) {
                                this.hospitalArr = data.result_data;
                                this.hospitalArr.map((item, index) => {
                                    this.$set(this.hospitalArr[index], "cities", []);
                                });
                            } else {
                                let citiesArr = data.result_data,
                                    len = citiesArr.length,
                                    pro = this.hospitalArr;
                                for (let i = 0, l = pro.length; i < l; i++) {
                                    if (pro[i].code != val[0]) continue;
                                    this.$set(pro[i], "cities", citiesArr);
                                    for (let j = 0; j < len; j++) {
                                        this.$set(pro[i].cities[j], "cities", []);
                                    }
                                }
                            }
                        } else {
                            this.$message.error(data.message);
                        }
                    })
                    .catch(err => console.log(err));
            } else {
                await this.$axios
                    .post("/web/common/hosSelect", this.$qs.stringify({ areCode: val[1] }))
                    .then(res => {
                        let data = res.data;
                        if (data.code == 1) {
                            let hospital = data.result_data,
                                len = this.hospitalArr.length;
                            hospital.forEach(e => (e.code = e.name));

                            for (let i = 0; i < len; i++) {
                                let el = this.hospitalArr[i];
                                if (el.code != val[0]) continue;
                                let cities = el.cities;
                                for (let j = 0, l = cities.length; j < l; j++) {
                                    if (cities[j].code != val[1]) continue;
                                    cities[j].cities = hospital;
                                }
                            }
                        } else {
                            this.$message.error(data.message);
                        }
                    })
                    .catch(err => console.log("err"));
            }
        },
        lazyLoad(node, resolve){
			if (!node) {  
				return false
			}
            const { label = '',value,level } = node;
            setTimeout(() => {
                this.$axios
                .post("/web/common/areaSelect", this.$qs.stringify({ code:value, level:level+1}))
                .then(rsp => {
                    if (rsp.data.code == 1) {
                        var areaList = rsp.data.result_data
                        var nodes = areaList.map(item => ({
                            value: item.code,
                            label: item.name,
                            leaf: level >= 1
                        }));
                        if(level>0){
                            this.localLabelName = label
                            this.reg.localName = ''
                            this.areaArr = nodes
                        }
                        // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                        resolve(nodes);
                    }
                })
            }, 1000);
        },
        // 上传认证证件限制
        uploadImg() {
            if (this.isConFlag) {
                this.$message.warning("该信息审核中或已被认证,不可直接更改,如有需求请联系客服");
                return;
            }
        },
        // 上传认证证件
        beforeAvatarUpload(file) {
            let fd = new FormData();
            fd.append("imgData", file);
            this.$axios.post("/SDpic/common/picUpload", fd).then(res => {
                let data = res.data;
                if (data.code == "1") {
                    this.reg.imgGid = data.imgGid;
                    this.reg.imageUrl = this.pic + data.imgGid;
                    this.imageTip = false
                    this.$message({
                        message: data.message,
                        type: "success"
                    });
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        /*
         * 个人认证信息表单提交
         */
        userConfirm() {
            if (this.isConFlag) {
                this.$message.warning("该信息审核中或已被认证,不可直接更改,如有需求请联系客服");
                return;
            }
            this.imageTip = !this.reg.imageUrl?true:false
            this.$refs.conForm.validate(vals => {
                if (!vals||!this.reg.imageUrl) return;
                if(!this.reg.localName&&this.areaArr.length){
                    this.areaArr.forEach(item=>{
                        if(this.reg.code[1] == item.value){
                            this.reg.localName = this.localLabelName + ',' + item.label
                        }
                    })
                }
                let handleHospital = (sH, wH) => {
                    return sH.length == 3 ? sH[2] : wH;
                };
                let reg = this.reg,
                    hos = handleHospital(reg.s_hospital, reg.w_hospital),
                    params = {
                        realName: reg.name,
                        hospital: hos,
                        department: reg.department,
                        jobtitle: reg.jobTitle,
                        sex: reg.gender,
                        avatars: reg.imgGid,
                        major: reg.major,
                        local : reg.localName
                    };
                this.$axios
                    .post("/SD/user/userAuth", this.$qs.stringify(params))
                    .then(res => {
                        let data = res.data;
                        if (data.code == "1") {
                            this.$alert("恭喜您认证成功，请继续观看视频！", "提示信息", {
                                showClose: false,
                                confirmButtonText: "确定",
                                type: "success",
                                callback: action => this.$router.push("/")
                            });
                        } else {
                            this.$message.error("认证失败！");
                        }
                    })
                    .catch(err => this.$message.error("网络错误！"));
            });
        }
    },
};
</script>
<style scoped src="../../assets/css/user-info.css"></style>
<style lang="less" scoped>
.upload /deep/ .el-form-item__error{
    margin-top: 6px;
}
.el-form-item /deep/ .el-input__inner:focus{
    border-color:#5680FA;
}
.el-form-item /deep/ .is-active,.el-form-item /deep/ .is-active:hover,.el-form-item /deep/ .is-focus .el-input__inner{
    border-color:#5680FA;
}
.el-select-dropdown__item.selected,
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    color: #5680FA;
}
</style>
