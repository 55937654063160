<template>
    <div id="register">
        <div class="reg-con">
            <div class="tips">
                <h3>用户认证注册</h3>
            </div>
            <authent-form :from="'register'" :details="reg" :btnText="'立即认证注册'" :isConFlag = "isConFlag"></authent-form>
        </div>
    </div>
</template>

<script>
import authentForm from '../Common/AuthentForm.vue'
export default {
    name: "Register",
    components: {authentForm},
    data() {
        return {
            pic: "https://www.orthoday.com/SDpic/common/picSelect?gid=",
            isConFlag:false,
            reg: {
                name: "",
                gender: "",
                s_hospital: [],
                w_hospital: "",
                department: "",
                jobTitle: "",
                code:[],
                major:"",
                imageUrl:"",
                localName:"",
                imgGid: "",
            },
        };
    },
    created() {
        let userId = localStorage.getItem("userId");
        !!userId ? this.getUserInfo(userId) : this.$router.push("/");
    },
    methods: {
        getUserInfo(userSelectGid) {

            this.$axios
                .post("/web/user/select", this.$qs.stringify({ chGid: "", userSelectGid }))
                .then(res => {
                    let data = res.data;
                    if (data.code == "1") {
                        let dt = data.result_data[0];
                        this.reg.name = dt.realName;
                        this.reg.department = dt.department;
                        this.reg.jobTitle = dt.jobTitle;
                        this.reg.w_hospital = dt.hospital;
                        this.reg.major = dt.major||"";
                        this.reg.localName = dt.local;

                        if(dt.sex){
                            this.reg.gender = dt.sex == 1 ? "男" : "女";
                        }
                        if (dt.approvePic) {
                            this.reg.imageUrl = this.pic + dt.approvePic;
                            this.reg.imgGid = dt.approvePic;
                        }
                        if (dt.approveStatus=='1'||dt.approveStatus=='2') {
                            this.isConFlag = true;
                        }
                    } else {
                        this.$message({
                            message: data.message,
                            type: "warning"
                        });
                    }
                })
                .catch(err => console.log("err"));
        },
    }
};
</script>

<style scoped src="../../assets/css/login-reg.css"></style>
<style scoped>
.reg-con /deep/ .avatar-uploader,.reg-con /deep/ .avatar-uploader .el-upload {
    width: 148px !important;
    height: 148px !important;
}
.reg-con /deep/ .avatar-uploader-icon {
    width: 148px;
    height: 148px;
    margin-top: 0;
    line-height: 148px;
    background-color: #ffffff;
    border: 2px dashed #dddddd;
}
.reg-con /deep/ .el-icon-plus:before{
    color: #cccccc;
}
.reg-con /deep/ img.avatar {
    width: 148px;
    height: 148px;
    margin-top: 0;
}
.reg-con /deep/ .el-button {
    margin: 20px 0;
}
</style>
