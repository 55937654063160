<template>
  <div class="pop">
    <van-dialog
      v-model="show"
      title="法律声明"
      :show-confirm-button="false"
      :closeOnClickOverlay="true"
      :overlay-style="{ 'z-index': 10100 }"
      className="myDialog"
    >
      <div class="allText">
        <div class="mainText">
          <p>
            本页面所包含的内容均仅代表讲者本人或者该第三方的内容和观点，与骨今中外的立场无关。骨今中外不对其中包含或引用的信息的准确性、可靠性或完整性提供任何明示或暗示的保证。
          </p>
          <p>医疗健康人士注意：</p>
          <p>
            播放内容仅供医学教育目的，请勿将播放内容对任何他人传播。播放内容仅向受邀医疗健康人士开放。尽管术者/讲课顾问尽力在播放内容中提供最新的医疗教育、交流信息，这些内容不应被认为是治疗的标准，且不代表其他普遍治疗方法。
          </p>
          <p>非医疗健康人士注意：</p>
          <p>
            本播放内容仅向受邀的专业医疗健康人士开放，为您身心健康之考虑，请您酌情观看。
          </p>
          <p>
            我们也建议在作出任何评价或治疗决定前请咨询其他专业人士并查阅其他参考资料。
          </p>
          <p>
            对于任何因使用本页面内容而可能遭致的意外、疏忽、侵权及其造成的损失，骨今中外不承担任何法律责任。
          </p>
          <p>
            如任何个人或其他媒体、网站欲转载使用本页面所载文章、视频、直播以及通过本页面使用或连接到的任何第三方的任何文字、图片或音、视频的，请与著作权人及骨今中外联系，并自负法律责任。
          </p>
          <!-- <p class="pButton" v-if="moreBtn"> -->
            <!-- <van-button
              round
              type="info"
              size="mini"
              class="bottom"
              @click="moreBtn = false"
            >
              下滑查看更多
            </van-button> -->
          <!-- </p> -->
        </div>
        <h2 class="countdown">{{ count }}秒后自动返回</h2>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: "PopUp",
  data() {
    return {
      show: true,
      count: 10,
      moreBtn: true,
    };
  },
  mounted() {
    //设置倒计时
    let timer = setInterval(() => {
      if (this.count == 1) {
        clearInterval(timer);
      }
      this.count--;
    }, 1000);
    //监听滚动加载 让查看更多按钮消失

    let mainBox = document.querySelector(".mainText");
    mainBox.addEventListener("scroll", () => {
      this.moreBtn = false;
    });
  },
  watch: {
    count(val) {
      if (val == 0) {
        // this.show = false;
      }
    },
  },
  methods: {
    showPopup() {
      this.show = true;
    },
  },
};
</script>

<style  scoped>
.pop {
  /* background-color: #0ff; */
}

.myDialog {
  height: 330px;
  margin-top: 15px;
  font-size: 20px;
  color: rgb(187, 46, 35);
  z-index: 2001;
  text-align: center;
  padding-top: 15px;
}

.closeBtn {
  position: absolute;
}

.allText {
  position: relative;
  padding:  15px 20px;
  font-size: 14px;
  color: black;
  line-height: 20px;
  text-align: left;
}
.mainText {
  height: 250px;
  overflow: auto;
}

.pButton {
  width: 100%;
  height: 1.6rem;
  position: absolute;
  bottom: 0rem;
  left: 50%;
  transform: translate(-50%);
  background-color: rgba(255, 255, 255, 0.83);
}

.bottom {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
  background-color: rgb(135, 135, 135);
  color: white;
}

.countdown {
  position: relative;
  text-align: center;
  width: 100%;

  color: gray;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
}
</style>
