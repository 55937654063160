<template>
    <div id="header">
        <div class="nav">
            <router-link to="/" tag="h1">
                <img src="../assets/img/logo.png" draggable="false" />
            </router-link>
            <ul class="nav_li">
                <router-link to="/" tag="li" exact>主页</router-link>
                <router-link to="/liveList" tag="li">直播课程</router-link>
                <router-link to="/video" tag="li">视频讲座</router-link>
                <router-link to="/course" tag="li">独家课程</router-link>
                <!-- <router-link to="/train" tag="li">线下培训</router-link> -->
                <router-link to="/member" tag="li">开通会员</router-link>
            </ul>

            <router-link to="/BoneAdd" tag="h1">
                <div class="ewm">
                    <img src="../assets/img/nav_add.png" class="ico" draggable="false" />
                    <p>发布病例</p>
                </div>
            </router-link>

            <router-link to="/Search" tag="h1">
                <div class="ewm">
                    <img src="../assets/img/nav_search.png" class="ico" draggable="false" />
                    <p>搜索</p>
                </div>
            </router-link>

            <div class="ewm">
                <img src="../assets/img/nav_phone.png" class="ico" draggable="false" />
                <p>下载App</p>
                <img src="../assets/img/app_ewm.png" class="app_code" draggable="false" />
            </div>

            <div class="userBtn">
                <div class="reg_login" v-if="!token">
                    <router-link to="/login" tag="span">登录</router-link>
                </div>
                <div class="userInfo" v-if="token">
                    <img :src="avatars ? pic + avatars : require('../assets/img/avatar.png')" draggable="false" />
                    <ul class="li_btn">
                        <router-link to="/user" tag="li">个人信息</router-link>
                        <router-link to="/myVideo" tag="li">我的视频</router-link>

                        <li @click="logOut">退出</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Bus } from "../tools/bus";
export default {
    name: "Header",
    data() {
        return {
            pic: "https://www.orthoday.com/SDpic/common/picSelect?gid=",
            token: localStorage.getItem("token") || "",
            avatars: sessionStorage.getItem("userAvatar") || ""
        };
    },
    mounted() {
        // 获取token响应值
        Bus.$on("setToken", val => {
            if (val) {
                this.token = localStorage.getItem("token");
                this.avatars = sessionStorage.getItem("userAvatar");
            } else {
                sessionStorage.clear();
                localStorage.removeItem("token");
                localStorage.removeItem("userId");
                this.token = "";
                this.avatars = "";
            }
        });
    },
    methods: {
        // 退出登录
        logOut() {
            sessionStorage.clear();
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            this.token = "";
            this.avatars = "";
            this.$router.push({ path: "/login" });
        }
    }
};
</script>

<style scoped src="../assets/css/header.css"></style>
