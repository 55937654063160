<template>
    <div id="footer">
        <img src="../assets/img/foot.png" class="footBg" draggable="false" />
        <div class="tipBar">
            <div class="tel">
                <img
                    src="../assets/img/lxr.png"
                    class="ico"
                    draggable="false"
                />
                <p>客服专线</p>
                <img
                    src="../assets/img/call.png"
                    class="tel_pop"
                    draggable="false"
                />
            </div>
            <div class="ewm" style="margin-top:20px;">
                <img
                    src="../assets/img/ewm.png"
                    class="ico"
                    draggable="false"
                />
                <p>二维码</p>
                <img
                    src="../assets/img/app_code.png"
                    class="ewm_pop"
                    draggable="false"
                />
            </div>
        </div>
        <div class="bah">
            <p>
                Copyright © 2020 All Rights Reserved. 北京上达医疗科技有限公司 ·
                版权所有
            </p>
            <p>
                <!-- <a href="https://beian.miit.gov.cn" target="view_window">
                    京ICP备16055887号
                </a> -->
            </p>
            <p>北京市海淀区澄湾街9号院1号楼三层</p>
        </div>

        <div style="width:200px;margin:0 auto;">
     		<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
		 	<img src="" style="float:left;"/>
		 	<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京ICP备16055887号-1</p >
		 </a>
    	</div>
		<div style="width:270px;margin:0 auto; padding:20px 0;">
		 		<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802036804" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="../assets/img/record.jpg" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备 11010802036804号</p></a>
		 	</div>

    </div>
</template>

<script>
export default {
    name: "Footer"
};
</script>

<style scoped src="../assets/css/footer.css"></style>
<style scoped>
div.bah {
    padding: 16px 0;
    background-color: #25242a;
    width: 100%;
    text-align: center;
    color: #fff;
}
div.bah > p {
    line-height: 2;
    letter-spacing: 1px;
}

div.bah > p a {
    color: #fff;
    text-decoration: none;
}
div.bah > p a:hover {
    text-decoration: underline;
    color: cornflowerblue;
}
</style>
