import Vue from "vue";

import {
    Area,
    Button,
    Cell,
    CellGroup,
    Icon,
    Image,
    Row,
    Col,
    Popup,
    Checkbox,
    CheckboxGroup,
    DatetimePicker,
    Field,
    Picker,
    RadioGroup,
    Radio,
    Search,
    Uploader,
    Dialog,
    DropdownMenu,
    DropdownItem,
    Notify,
    PullRefresh,
    Toast,
    Collapse,
    CollapseItem,
    Divider,
    Lazyload,
    List,
    Swipe,
    SwipeItem,
    Grid,
    GridItem,
    NavBar,
    Sidebar,
    SidebarItem,
    Tab,
    Tabs,
    Tabbar,
    TabbarItem,
    TreeSelect,
    AddressEdit,
    Overlay,
    Step,
    Steps,
    Loading,
    NoticeBar,
    Form,
    Calendar,
    Empty,
    Sticky,
    CountDown,
    IndexBar,
    IndexAnchor,
    ActionSheet
    // Popup,
} from "vant";

Vue.use(Area);
Vue.use(Button);
Vue.use(Cell).use(CellGroup);
Vue.use(Icon);
Vue.use(Image);
Vue.use(Row).use(Col);
Vue.use(Popup);
Vue.use(Checkbox).use(CheckboxGroup);
Vue.use(DatetimePicker);
Vue.use(Field);
Vue.use(Picker);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Search);
Vue.use(Uploader);
Vue.use(Dialog);
Vue.use(DropdownMenu).use(DropdownItem);
Vue.use(Notify);
Vue.use(PullRefresh);
Vue.use(Toast);
Vue.use(Collapse).use(CollapseItem);
Vue.use(Divider);
Vue.use(Lazyload);
Vue.use(List);
Vue.use(Swipe).use(SwipeItem);
Vue.use(Grid).use(GridItem);
Vue.use(NavBar);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Tab).use(Tabs);
Vue.use(Tabbar).use(TabbarItem);
Vue.use(TreeSelect);
Vue.use(AddressEdit);
Vue.use(Overlay);
Vue.use(Step).use(Steps);
Vue.use(Loading);
Vue.use(NoticeBar);
Vue.use(Form);
Vue.use(Calendar);
Vue.use(Empty);
Vue.use(Sticky);
Vue.use(CountDown);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(ActionSheet);
// Vue.use(Popup);

Toast.setDefaultOptions("loading", { forbidClick: true, message: "加载中..." });
