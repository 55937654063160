<template>
    <ul class="wrap_lis">
        <template v-for="(item, index) in dataArray">
            <li class="wrap_li" :key="index" @click="sendParam(item.gid, item.watchNum, item.type)">
                <div class="img_wrap">
                    <el-image :src="pic + item.avatar" lazy draggable="false" fit="cover"></el-image>
                    <div class="img_bottom">
                        <img src="../../assets/img/view.png" draggable="false" />
                        <!-- {{item}} -->
                        <span>{{ item.watchNum || item.watchNumber }}人浏览</span>
                        <span class="c_num">{{ item.classNum ? '共' + item.classNum + '课时' : '' }}</span>
                    </div>
                </div>
                <div class="li_desc">
                    <p class="v_desc" :title="item.title">{{ item.title }}</p>
                    <p class="v_author">{{ item.expertName }}</p>
                </div>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    name: "DataList",
    props: ["dataArray"],
    data() {
        return {
            pic: "https://www.orthoday.com/SDpic/common/picSelect?gid="
        };
    },
    methods: {
        sendParam(gid, watchNum, type) {

            var dic = { gid, type };
            this.$emit("transfer", dic);
            sessionStorage.setItem("w-num", watchNum);
        }
    }
};
</script>

<style scoped src="../../assets/css/data-list.css"></style>